import styled from "styled-components";

export const Body = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  padding: 0 16px;
  flex-grow: 2;
`;

export const AlertWrapper = styled.div`
  padding: 26px 0;
  color: #70757a;
`;

export const Results = styled.div`
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;

  content-visibility: auto;
  contain-intrinsic-size: 1000px; /* Explained in the next section. */

  & > * {
    margin: 10px 10px 10px 0;
    width: 100%;
  }
`;

export const EmptyResults = styled.div`
  display: flex;

  justify-content: center;
`;

export const ResultTitle = styled.div`
  min-height: 30px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;

  & > a {
    font-weight: bold;
    text-decoration: none;
  }
  & > span > a {
    text-decoration: none;
  }
`;

export const ResultBody = styled.div`
  min-height: 80px;
`;

export const HighlightResult = styled.p`
  margin: 0;

  & em {
    background-color: yellow;
    font-style: normal;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  background: #2c3e50;
  padding: 5px 24px;

  & > a {
    display: block;
  }

  & > a img {
    display: block;
    height: 37px;
  }

  @media only screen and (min-width: 768px) {
    & > a img {
      height: 65px;
    }
  }
`;

export const FooterWrapper = styled.div`
  margin-top: 40px;
  background: #2c3e50;
`;

export const Footer = styled.div`
  color: rgba(255, 255, 255, 0.66);
  line-height: 48px;
  padding: 24px 0;
  text-align: center;

  & > address {
    font-style: normal;
  }
`;

export const FooterName = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #bbb;
`;

export const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

export const SearchSuffix = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 12px;
`;

export const HeaderWrapper = styled.div`
  background: linear-gradient(0deg, #f5f5f5, #fff);
  margin-bottom: 24px;
`;

export const Header = styled.div`
  color: #333;
  padding: 0 24px;
`;

export const AttachmentContainer = styled.div`
  margin-top: 16px;
  padding-top: 18px;
`;

export const AttachmentItem = styled.div`
  display: flex;
  align-items: center;

  color: #555;
  & a {
    margin-left: 8px;
    color: #555;
  }
`;

export const AppTitle = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 600;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }

  & a {
    color: #333;
    text-decoration: none;
    display: block;

    line-height: 60px;
    @media only screen and (min-width: 768px) {
      line-height: 80px;
    }

    &:hover {
      color: #0d5661;
      transition: all 0.3s;
    }
  }
`;

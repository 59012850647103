/* eslint-disable-file react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import { convertToTraditionalChinese } from "zh_cn_zh_tw";
import "./App.css";
import Card, { CardSection } from "@kiwicom/orbit-components/lib/Card";
import List, { ListItem } from "@kiwicom/orbit-components/lib/List";
import InputField from "@kiwicom/orbit-components/lib/InputField";
import Pagination from "@kiwicom/orbit-components/lib/Pagination";
import Search from "@kiwicom/orbit-components/lib/icons/Search";
import Illustration from "@kiwicom/orbit-components/lib/Illustration";
import ChoiceGroup from "@kiwicom/orbit-components/lib/ChoiceGroup";
import Checkbox from "@kiwicom/orbit-components/lib/Checkbox";
import Collapse from "@kiwicom/orbit-components/lib/Collapse";
import Grid from "@kiwicom/orbit-components/lib/utils/Grid";

import Link from "@kiwicom/orbit-components/lib/icons/Link";
import Entertainment from "@kiwicom/orbit-components/lib/icons/Entertainment";
import CustomerSupport from "@kiwicom/orbit-components/lib/icons/CustomerSupport";

import AlgoliaSearch from "./algolia-blue-mark.svg";

import {
  Body,
  Content,
  AlertWrapper,
  AttachmentContainer,
  AttachmentItem,
  Results,
  EmptyResults,
  ResultTitle,
  ResultBody,
  HighlightResult,
  LogoWrapper,
  FooterWrapper,
  Footer,
  FooterName,
  FooterLink,
  SearchSuffix,
  HeaderWrapper,
  Header,
  AppTitle
} from "./Components";

const PER_PAGE = 10;

const INDEX_RANK = {
  "school.rccc.org": 100,
  son: 99,
  "cn.rccc.org": 98,
  "en.rccc.org": 98,
  "ec.rccc.org": 98,
  "women.rccc.org": 90,
  "rbsg.rccc.org": 80,
  archive: 10
};

function sortIndexes(i1, i2) {
  const i1n = i1.name.toLowerCase();
  const i2n = i2.name.toLowerCase();

  const i1r = INDEX_RANK[i1n] || 0;
  const i2r = INDEX_RANK[i2n] || 0;

  return i2r - i1r;
}

function getURLQuery(key) {
  if (window.location.search.length > 0) {
    const q = new URLSearchParams(window.location.search);
    const value = q.get(key);

    return value || "";
  } else {
    return "";
  }
}

function updateURLQuery(key, value) {
  const q = new URLSearchParams(window.location.search);

  q.set(key, value);

  window.history.replaceState(null, `Query ${value}`, "/?" + q.toString());
}

function App() {
  const fromIndex = getURLQuery("from").toLowerCase();

  const [keyword, setKeyword] = useState(getURLQuery("q"));
  const [indexes, setIndexes] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (keyword.length > 0) {
      fetch(
        `/.netlify/functions/search?s=${convertToTraditionalChinese(
          keyword
        )}&indexes=${selectedIndexes.join(",")}&from=${fromIndex}`
      ).then(async res => {
        const newItems = await res.json();

        setItems(newItems);
      });
    } else {
      setItems([]);
    }
  }, [keyword, fromIndex, selectedIndexes, setItems]);

  useEffect(() => {
    fetch(`/.netlify/functions/indexes`).then(async res => {
      const newIndexes = await res.json();
      setIndexes(newIndexes.sort(sortIndexes));
      setSelectedIndexes(newIndexes.map(i => i.name));
    });
  }, [setIndexes, setSelectedIndexes]);

  return (
    <Body>
      <HeaderWrapper>
        <LogoWrapper>
          <a href="https://cn.rccc.org/" aria-label="Link">
            <img
              alt="Rutgers Community Christian Church"
              src="./rccc-logo-sm.png"
              srcSet="./rccc-logo-sm.png 300w, ./rccc-logo-lg.png 600w"
              sizes="(max-width: 768px) 300px, 600px"
            />
          </a>
        </LogoWrapper>
        <Header>
          <AppTitle>
            <a href="/">若歌教會全站搜索引擎</a>
          </AppTitle>
        </Header>
      </HeaderWrapper>
      <Content>
        <div style={{ marginBottom: "8px" }}>
          <InputField
            prefix={<Search />}
            suffix={
              <SearchSuffix
                src={AlgoliaSearch}
                title="Search powered by Algolia"
              />
            }
            value={keyword}
            onChange={evt => {
              setKeyword(evt.target.value);

              updateURLQuery("q", evt.target.value);

              setPage(1);
            }}
            placeholder="輸入關鍵字"
          />
        </div>
        <Collapse label="搜索範圍">
          <ChoiceGroup
            onChange={e => {
              const selected = e.target.value;
              if (selectedIndexes.includes(selected)) {
                setSelectedIndexes(selectedIndexes.filter(i => i !== selected));
              } else {
                setSelectedIndexes([...selectedIndexes, selected]);
              }
            }}
          >
            {indexes.map(index => {
              return (
                <Checkbox
                  key={index.name}
                  checked={selectedIndexes.includes(index.name)}
                  label={getFriendlyName(index.name.toLowerCase())}
                  value={index.name}
                />
              );
            })}
          </ChoiceGroup>
        </Collapse>
        {items.length > 0 && (
          <AlertWrapper>根據關鍵字找到{items.length}條匹配記錄</AlertWrapper>
        )}

        {items.length > 0 && (
          <Pagination
            pageCount={Math.ceil(items.length / PER_PAGE)}
            selectedPage={page}
            onPageChange={page => {
              setPage(page);
              window.scrollTo(0, 0);
            }}
          />
        )}

        <Results>
          {items.slice((page - 1) * PER_PAGE, page * PER_PAGE).map(item => (
            <ResultCard
              keyword={convertToTraditionalChinese(keyword)}
              {...item}
            />
          ))}
        </Results>

        {items.length === 0 && (
          <EmptyResults>
            <Illustration name="Help" />
          </EmptyResults>
        )}

        {items.length > 0 && (
          <Pagination
            pageCount={Math.ceil(items.length / PER_PAGE)}
            selectedPage={page}
            onPageChange={page => {
              setPage(page);
              window.scrollTo(0, 0);
            }}
          />
        )}
      </Content>
      <FooterWrapper>
        <Footer>
          <FooterName>Rutgers Community Christian Church</FooterName>
          <address>
            <FooterLink href="https://goo.gl/maps/gwqqUJcwFYLiFsy56">
              71 Cedar Grove Lane Somerset, NJ 08873
            </FooterLink>
          </address>
          <div>
            <FooterLink href="tel:+1-732-868-6700">+1-732-868-6700</FooterLink>
          </div>
          © {new Date().getFullYear()} Rutgers Community Christian Church{" "}
          <FooterLink href="https://drive.google.com/file/d/1gTyEgwgfRI1mKF4-4Y1POsKgCJLyQ2kn/view">
            隱私條款
          </FooterLink>
        </Footer>
      </FooterWrapper>
    </Body>
  );
}

function attachmentTypeToIcon(type) {
  if (type === "video") {
    return <Entertainment />;
  } else if (type === "audio") {
    return <CustomerSupport />;
  } else {
    return <Link />;
  }
}

function Attachments({ keyword, items }) {
  if (!items) {
    return null;
  }

  const matchedItems = items.filter(item => item.title.indexOf(keyword) !== -1);

  if (matchedItems.length === 0) {
    return null;
  }

  return (
    <AttachmentContainer>
      <Grid
        as="div"
        desktop={{
          columns: "repeat(2, minmax(100px, 1fr))",
          gap: "16px",
          rows: "repeat(4, 32px)"
        }}
        largeDesktop={{
          columns: "repeat(8, minmax(10px, 1fr))",
          rows: "16px"
        }}
        largeMobile={{
          columns: "repeat(4, 1fr)",
          gap: "16px",
          rows: "repeat(2, 32px)"
        }}
        mediumMobile={{
          rowGap: "0"
        }}
        rowGap="10px"
        tablet={{
          columnGap: "16px"
        }}
        width="100%"
      >
        {matchedItems
          .filter(item => item.type === "video")
          .map(att => (
            <AttachmentItem key={att.title}>
              {attachmentTypeToIcon(att.type)}
              <a href={att.link} target="_blank">
                {highlight(att.title, keyword)}
              </a>
            </AttachmentItem>
          ))}
        {matchedItems
          .filter(item => item.type === "audio")
          .map(att => (
            <AttachmentItem key={att.title}>
              {attachmentTypeToIcon(att.type)}
              <a href={att.link} target="_blank">
                {highlight(att.title, keyword)}
              </a>
            </AttachmentItem>
          ))}
        {matchedItems
          .filter(item => item.type === "document")
          .map(att => (
            <AttachmentItem key={att.title}>
              {attachmentTypeToIcon(att.type)}
              <a href={att.link} target="_blank">
                {highlight(att.title, keyword)}
              </a>
            </AttachmentItem>
          ))}
      </Grid>
    </AttachmentContainer>
  );
}

function ResultCard({ type, keyword, ...attrs }) {
  if (type === "SON") {
    const {
      title,
      speaker,
      scripture,
      congregation,
      date,
      attachments,
      recordingLink
    } = attrs;

    return (
      <Card>
        <CardSection>
          <ResultTitle>
            <a href={recordingLink} target="_blank" rel="noopener">
              {highlight(title, keyword)}
            </a>{" "}
            <span>
              {date} [
              <a
                href="http://www.rccc.org/Sermon/home"
                target="_blank"
                rel="noopener"
              >
                講道庫
              </a>
              ]
            </span>
          </ResultTitle>
          <ResultBody>
            <List>
              <ListItem>{highlight(scripture, keyword)}</ListItem>
              <ListItem>{highlight(speaker, keyword)}</ListItem>
              <ListItem>{congregation}</ListItem>
            </List>
            <Attachments keyword={keyword} items={attachments} />
          </ResultBody>
        </CardSection>
      </Card>
    );
  } else {
    const {
      topicName,
      title,
      date,
      link,
      attachments,
      _highlightResult
    } = attrs;

    const source = (topicName || type).toLowerCase();

    return (
      <Card>
        <CardSection>
          <ResultTitle>
            <a href={link} target="_blank" rel="noopener">
              {highlight(title, keyword)}
            </a>
            <span>
              {date && `${date} `}[
              {isLink(source) ? (
                <a href={`https://${source}`} target="_blank" rel="noopener">
                  {getFriendlyName(source)}
                </a>
              ) : (
                source
              )}
              ]
            </span>
          </ResultTitle>
          <ResultBody>
            <HighlightResult
              dangerouslySetInnerHTML={{
                __html: shortenLongResult(_highlightResult.content?.value)
              }}
            />
            <Attachments keyword={keyword} items={attachments} />
          </ResultBody>
        </CardSection>
      </Card>
    );
  }
}

function ellipsify(s) {
  return `${s.slice(0, 20)}...${s.slice(s.length - 20)}`;
}

function shortenLongResult(result) {
  if (result.length < 200) {
    return result;
  }

  // remove long head / long tail
  const startOfFirstKeyword = result.indexOf("<em>");
  const endOfLastKeyword = result.lastIndexOf("</em>");

  let head = result.slice(0, startOfFirstKeyword);
  const body = result.slice(startOfFirstKeyword, endOfLastKeyword);
  let tail = result.slice(endOfLastKeyword);

  if (head.length > 100) {
    head = ellipsify(head);
  }

  if (tail.length > 100) {
    tail = ellipsify(tail);
  }

  return `${head}${body}${tail}`;
}

function isLink(source) {
  return source.endsWith(".rccc.org");
}

function getFriendlyName(source) {
  if (source === "school.rccc.org") {
    return "主日學";
  }

  if (source === "cn.rccc.org") {
    return "中文主站";
  }

  if (source === "en.rccc.org" || source === "ec.rccc.org") {
    return "英文主站";
  }

  if (source === "rbsg.rccc.org") {
    return "若歌學生查經班";
  }

  if (source === "women.rccc.org") {
    return "若歌教會婦女事工";
  }

  if (source === "son") {
    return "講道庫";
  }

  if (source === "archive") {
    return "歷史存檔";
  }

  return source;
}

function highlight(content, keyword) {
  return (
    <Highlighter
      highlightClassName="keyword"
      searchWords={keyword.split(" ")}
      autoEscape={true}
      textToHighlight={content}
    />
  );
}

export default App;
